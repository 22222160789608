<template>
  <div class="evaluation_report" :style="{ opacity: opacity }">
    <navigationBar v-if="false" :showBack="showBack" :back="back">{{
      name
    }}</navigationBar>
    <reportItem ref="reportItem" v-if="false"></reportItem>
    <van-popup
      v-model="showScore"
      v-if="false"
      :close-on-click-overlay="clickFalg"
      ><score
        @handelClose="handelClose"
        @getEvaluationReport="getEvaluationReport"
        :code="code"
      ></score>
    </van-popup>

    <div class="one detail-body-first" :style="{ height: pageHeight + 'px' }">
      <img class="logo" src="../assets/images/cp_logo.png" alt="" />
      <div class="info">
        <div class="title">{{ info.name }}报告</div>
        <div class="item_info">
          <div class="left_box">姓名：</div>
          <div class="right_box">{{ info.realName }}</div>
        </div>
        <div class="item_info">
          <div class="left_box">性别：</div>
          <div class="right_box">{{ info.sex }}</div>
        </div>
        <div class="item_info">
          <div class="left_box">身份证号：</div>
          <div class="right_box">{{ info.idCard }}</div>
        </div>
        <div class="item_info">
          <div class="left_box">手机号：</div>
          <div class="right_box">{{ info.phoneNum }}</div>
        </div>
        <div class="item_info">
          <div class="left_box">测评机构：</div>
          <div class="right_box">{{ info.institutionName }}</div>
        </div>
        <div class="item_info">
          <div class="left_box">报告编号：</div>
          <div class="right_box">{{ info.certificateNumber }}</div>
        </div>
        <div class="item_info" v-if="info.timeLine > 0">
          <div class="left_box">测评用时：</div>
          <div class="right_box">{{ formatSeconds(info.timeLine) }}</div>
        </div>
        <div class="item_info">
          <div class="left_box">报告日期：</div>
          <div class="right_box">{{ info.createDateStr }}</div>
        </div>
      </div>
      <div class="reading_guide">
        <div class="reading_guide_title">阅读指南</div>
        <div class="reading_guide_content">
          <span v-for="(item, index) in info.readingGuide" :key="index">{{item}}</span>
        </div>
      </div>
      <div class="tips">
        测评模型、测评报告、平台技术等均已获取知识产权保护。
      </div>
    </div>
    <div
      class="page"
      :style="{ height: pageHeight + 'px' }"
      v-for="(item, index) in pageList"
      :key="index"
    >
      <!-- 页头 -->
      <div class="title_box detail-body-first">
        <div class="left_box"></div>
        <div class="right_box">
          <div class="num">报告编号：{{ info.certificateNumber }}</div>
        </div>
      </div>

      <!-- 页尾 -->
      <div class="footer_box">
        <div class="left_box">
          <img class="footer1" src="../assets/images/footer1.png" alt="" />
          测评机构：启创未来（北京）科技有限公司
        </div>
        <div class="right_box">
          {{ index >= 9 ? index + 1 : `0${index + 1}` }}
        </div>
      </div>
    </div>
    <div class="two">
      <div class="item" v-for="(item, index1) of tabInfos" :key="index1">
        <div class="big_title_box detail-item">
          <div class="big_title_box_wrap">
            <div class="big_title">{{ item.tabValue }}</div>
          </div>
        </div>
        <div
          class="text"
          v-for="(children, index) of item.dataShows"
          :key="index"
        >
          <!-- 文字描述 -->
          <div v-if="children.dataType == 2" class="content_text">
            <p
              class="detail-item"
              v-for="(grandson, index) of children.data"
              :key="index"
              style="
                background-color: #f5f9ff;
                color: #333333;
                padding: 0 0.16rem 0 0.16rem;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ grandson }}
            </p>
          </div>
          <!-- 标题描述 -->
          <div v-if="children.dataType == 3">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                style="font-size: 0.32rem; font-weight: 600"
                class="detail-item"
                v-if="grandson.title"
              >
                {{ grandson }}
              </h4>
            </div>
          </div>
          <!-- 标题文字表述 -->
          <div v-if="children.dataType == 4" class="content_text">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.28rem;
                  color: #005fe7;
                  font-weight: 600;
                  padding: 2px 0.16rem 10px 0.16rem;
                  background-color: rgb(245, 249, 255);
                "
                v-if="grandson.title"
              >
                {{ grandson.title }}
              </h4>
              <p
                class="detail-item"
                v-for="(i, index) of grandson.descriptions"
                :key="index"
                style="
                  background-color: #f5f9ff;
                  color: #333333;
                  padding: 0 0.16rem 0 0.16rem;
                  text-indent: 2em;
                  text-align: justify;
                  font-size: 0.26rem;
                "
              >
                {{ i }}
              </p>
            </div>
          </div>
          <!-- 标题文字表述 -->
          <div v-if="children.dataType == 41" class="content_text">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.28rem;
                  color: #005fe7;
                  font-weight: 600;
                  padding: 2px 0.16rem 10px 0.16rem;
                  background-color: rgb(245, 249, 255);
                "
                v-if="grandson.title"
              >
                {{ grandson.title }}
              </h4>
              <p
                class="detail-item"
                v-for="(i, index) of grandson.descriptions"
                :key="index"
                style="
                  background-color: #f5f9ff;
                  color: #333333;
                  padding: 0 0.16rem 0 0.16rem;
                  text-indent: 2em;
                  text-align: justify;
                  font-size: 0.26rem;
                "
              >
                <span
                  style="
                    width: 0.14rem;
                    height: 0.14rem;
                    display: inline-block;
                    margin-right: 0.1rem;
                    background: #999999;
                    border-radius: 50%;
                    line-height: 0.2rem;
                  "
                ></span
                >{{ i }}
              </p>
            </div>
          </div>
          <!-- 图片 -->
          <div v-if="children.dataType == 5">
            <div
              v-for="(grandson, index) of children.data"
              :key="index"
              v-html="grandson"
              class="detail-item image_box"
            ></div>
          </div>
          <!-- 三级标题带内容 -->
          <div v-if="children.dataType == 6" class="content_text">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.26rem;
                  color: #005fe7;
                  font-weight: 500;
                  padding: 2px 0.16rem 10px 0.16rem;
                  background-color: rgb(245, 249, 255);
                  text-indent: 2em;
                "
                v-if="grandson.title"
              >
                {{ grandson.title }}
              </h4>
              <p
                class="detail-item"
                v-for="(i, index) of grandson.descriptions"
                :key="index"
                style="
                  font-size: 0.26rem;
                  background-color: #f5f9ff;
                  color: #333333;
                  padding: 0 0.16rem 0 0.16rem;
                  text-indent: 2em;
                  text-align: justify;
                "
              >
                {{ i }}
              </p>
            </div>
          </div>
          <!-- 新加 三级标题带内容-特殊符号 -->
          <div v-if="children.dataType == 61" class="content_text">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.26rem;
                  color: #005fe7;
                  font-weight: 500;
                  padding: 2px 0.16rem 10px 0.16rem;
                  background-color: rgb(245, 249, 255);
                  text-indent: 2em;
                "
                v-if="grandson.title"
              >
                {{ grandson.title }}
              </h4>
              <p
                class="detail-item"
                v-for="(i, index) of grandson.descriptions"
                :key="index"
                style="
                  font-size: 0.26rem;
                  background-color: #f5f9ff;
                  color: #333333;
                  padding: 0 0.16rem 0 0.16rem;
                  text-indent: 2em;
                  text-align: justify;
                "
              >
                <span
                  style="
                    width: 0.14rem;
                    height: 0.14rem;
                    display: inline-block;
                    margin-right: 0.1rem;
                    background: #999999;
                    border-radius: 50%;
                    line-height: 0.2rem;
                  "
                ></span
                >{{ i }}
              </p>
            </div>
          </div>
          <!-- 三级标题带内容 -->
          <div v-if="children.dataType == 7" class="content_text">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.26rem;
                  color: #005fe7;
                  font-weight: 500;
                  padding: 2px 0.16rem 10px 0.16rem;
                  background-color: rgb(245, 249, 255);
                  text-indent: 2em;
                "
                v-if="grandson.title"
              >
                {{ grandson.title }}
              </h4>
              <p
                class="detail-item"
                v-for="(i, index) of grandson.descriptions"
                :key="index"
                style="
                  font-size: 0.26rem;
                  background-color: #f5f9ff;
                  color: #333333;
                  padding: 0 0.16rem 0 0.16rem;
                  text-indent: 2em;
                  text-align: justify;
                "
              >
                {{ i }}
              </p>
            </div>
          </div>
          <!-- 四级标题带内容 -->
          <!-- <div v-if="children.dataType == 7" class="content_text">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.26rem;
                  color: #333333;
                  font-weight: 600;
                  padding: 2px 0.16rem 10px 0.16rem;
                  background-color: rgb(245, 249, 255);
                  text-indent: 2em;
                "
                v-if="grandson.title"
              >
                {{ grandson.title }}
              </h4>
              <p
                class="detail-item"
                v-for="(i, index) of grandson.descriptions"
                :key="index"
                style="
                  background-color: #f5f9ff;
                  color: #333333;
                  padding: 0 0.16rem 0 0.16rem;
                  text-indent: 2em;
                  text-align: justify;
                  font-size: 0.26rem;
                "
              >
                {{ i }}
              </p>
            </div>
          </div> -->
          <div v-if="children.dataType == 71">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.28rem;
                  color: #005fe7;
                  font-weight: 600;
                  padding: 2px 0.16rem 10px 0.16rem;
                  background-color: rgb(245, 249, 255);
                "
                v-if="grandson.title"
              >
                {{ grandson.title }}
              </h4>
              <p
                class="detail-item"
                v-for="(i, index) of grandson.descriptions"
                :key="index"
                style="
                  background-color: #f5f9ff;
                  color: #333333;
                  padding: 0 0.16rem 0 0.16rem;
                  text-indent: 2em;
                  text-align: justify;
                  font-size: 0.26rem;
                "
              >
                <span
                  style="
                    width: 0.14rem;
                    height: 0.14rem;
                    display: inline-block;
                    margin-right: 0.1rem;
                    background: #999999;
                    border-radius: 50%;
                    line-height: 0.2rem;
                  "
                ></span
                >{{ i }}
              </p>
            </div>
          </div>
          <!-- 81 加粗文本带内容-特殊符号 -->
          <div v-if="children.dataType == 81">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.28rem;
                  color: #005fe7;
                  font-weight: 600;
                  padding: 2px 0.16rem 10px 0.16rem;
                  background-color: rgb(245, 249, 255);
                "
                v-if="grandson.title"
              >
                {{ grandson.title }}
              </h4>
              <p
                class="detail-item"
                v-for="(i, index) of grandson.descriptions"
                :key="index"
                style="
                  background-color: #f5f9ff;
                  color: #333333;
                  padding: 0 0.16rem 0 0.16rem;
                  text-indent: 2em;
                  text-align: justify;
                  font-size: 0.26rem;
                "
              >
                <span
                  style="
                    width: 0.14rem;
                    height: 0.14rem;
                    display: inline-block;
                    margin-right: 0.1rem;
                    background: #999999;
                    border-radius: 50%;
                    line-height: 0.2rem;
                  "
                ></span
                >{{ i }}
              </p>
            </div>
          </div>
          <!-- 加粗文本 -->
          <div v-if="children.dataType == 8">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.26rem;
                  color: #333333;
                  font-weight: 600;
                  padding: 2px 0.16rem 10px 0.16rem;
                  background-color: rgb(245, 249, 255);
                  text-indent: 2em;
                "
              >
                {{ grandson }}
              </h4>
            </div>
          </div>
          <!-- 图表-雷达图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 1"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <oneEcharts
              v-if="children.data.chartType == 1"
              :dataEcharts="children.data.chartData"
            ></oneEcharts>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-柱状图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 2"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <twoEcharts
              v-if="children.data.chartType == 2"
              :dataEcharts="children.data.chartData"
            ></twoEcharts>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-横向柱状图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 3"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <threeEcharts
              v-if="children.data.chartType == 3"
              :dataEcharts="children.data.chartData"
            ></threeEcharts>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-饼状图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 4"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <fourEcharts
              v-if="children.data.chartType == 4"
              :dataEcharts="children.data.chartData"
            ></fourEcharts>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-表示最大最小图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 5"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <div class="result-table">
              <div class="chart-data-title" v-if="children.data.title">
                {{ children.data.title }}
              </div>
              <div style="text-align: center">
                {{ children.data.chartData.titleText }}
              </div>
              <div>
                <div class="result-table-body">
                  <div class="result-table-body-header">
                    <div class="result-table-body-left-title">
                      {{ children.data.chartData.data[0].lable }}
                    </div>
                    <div class="result-table-body-right-title">
                      {{ children.data.chartData.data[1].lable }}
                    </div>
                  </div>
                  <div class="result-table-body-center">
                    <div class="result-table-cent-left">
                      <div
                        class="result-table-left-centent"
                        :style="
                          'width:' + children.data.chartData.data[0].baifenbi
                        "
                      ></div>
                    </div>
                    <div class="result-table-cent-right">
                      <div
                        class="result-table-right-centent"
                        :style="
                          'width:' + children.data.chartData.data[1].baifenbi
                        "
                      ></div>
                    </div>
                  </div>
                  <div class="result-table-body-bottom">
                    <div class="result-table-cent-left">
                      <div style="display: inline-block; float: left">
                        {{ children.data.chartData.data[0].max }}
                      </div>
                      <div
                        class="result-table-left-centent"
                        :style="
                          'width:' + children.data.chartData.data[0].baifenbi
                        "
                      ></div>
                      <span>{{ children.data.chartData.data[0].value }}</span>
                    </div>
                    <div class="result-table-cent-right">
                      <div style="display: inline-block; float: right">
                        {{ children.data.chartData.data[1].max }}
                      </div>
                      <div
                        class="result-table-right-centent"
                        :style="
                          'width:' + children.data.chartData.data[1].baifenbi
                        "
                      ></div>
                      <span style="vertical-align: top">{{
                        children.data.chartData.data[1].value
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="chart-data-descriptions"
                v-if="children.data.descriptions"
              >
                <div
                  v-for="(desItem, desIndex) in children.data.descriptions"
                  :key="desIndex"
                >
                  {{ desItem }}
                </div>
              </div>
            </div>
          </div>
          <!-- 图表 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 6"
            style="margin: 0.2rem 0"
          >
            <h4
              class="detail-item"
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
          </div>
          <div
            v-if="children.dataType == 1 && children.data.chartType == 6"
            style="margin: 0.2rem 0"
          >
            <div
              class="row detail-item"
              v-for="(grandson, index) of children.data.chartData.data"
              :key="index"
            >
              <div
                v-for="(grandson1, index1) of grandson.lineDatas"
                :key="index1"
              >
                <template v-if="grandson1.indexOf('https') != -1">
                  <img style="width: 80%" :src="grandson1" alt="" />
                </template>
                <template v-else>
                  {{ grandson1 }}
                </template>
              </div>
            </div>
            <p
              class="detail-item"
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-带负数柱状图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 7"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <sevenEcharts
              v-if="children.data.chartType == 7"
              :dataEcharts="children.data.chartData"
            ></sevenEcharts>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-双数据柱状图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 8"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <eightEcharts
              v-if="children.data.chartType == 8"
              :dataEcharts="children.data.chartData"
            ></eightEcharts>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-金字塔图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 9"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <nineEcharts
              v-if="children.data.chartType == 9"
              :dataEcharts="children.data.chartData"
            ></nineEcharts>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-对比柱状图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 10"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <tenEcharts
              v-if="children.data.chartType == 10"
              :dataEcharts="children.data.chartData"
            ></tenEcharts>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.22rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-双数据雷达图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 11"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <elevenEcharts
              v-if="children.data.chartType == 11"
              :dataEcharts="children.data.chartData"
            ></elevenEcharts>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-分数落点图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 12"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <div class="results-body">
              <div
                class="results-list"
                :style="
                  'background:' + resItem.color + ';width:' + resItem.proportion
                "
                v-for="(resItem, resIndex) in children.data.chartData
                  .resultsList"
                :key="resIndex"
              >
                <span>{{ resItem.name }}</span>
                <span class="results-label">{{ resItem.num }}</span>
                <span class="results-label-last" v-if="resIndex == 0">0</span>
              </div>
              <!-- <span class="results-body-label">0</span> -->
            </div>
            <div class="results-des">
              <div
                class="results-lable"
                :style="'left:' + children.data.chartData.resultsObj.proportion"
              >
                <div class="results-line"></div>
                <div>你的得分:</div>
                <div>
                  {{ children.data.chartData.resultsObj.number }}
                </div>
              </div>
            </div>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-仪表盘图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 13"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <thirteenEcharts
              v-if="children.data.chartType == 13"
              :dataEcharts="children.data.chartData"
            ></thirteenEcharts>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-对向柱状图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 14"
            class="detail-item"
          >
            <fourteenEcharts
              v-if="children.data.chartType == 14"
              :left="children.data.chartData"
            ></fourteenEcharts>
          </div>
          <!-- 图表-堆叠柱状图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 15"
            class="detail-item"
          >
            <div class="type15chart">
              <div
                class="centerZhu"
                :style="{
                  '--center': centerFun(
                    children.data.chartData.firstSeriesData
                  ),
                }"
                :data-left="children.data.chartData.firstSeriesData[0]"
                :data-right="children.data.chartData.firstSeriesData[1]"
              >
                <div
                  v-if="children.data.chartData.xaxisData"
                  class="center_left_tips"
                >
                  {{ children.data.chartData.xaxisData[0] }}
                </div>
                <div
                  v-if="children.data.chartData.xaxisData"
                  class="center_right_tips"
                >
                  {{ children.data.chartData.xaxisData[1] }}
                </div>
                <div
                  class="innerZhu"
                  :style="
                    returnStyle(
                      children.data.chartData.firstSeriesData,
                      children.data.chartData.secondSeriesData
                    )
                  "
                >
                  <div class="innerleft">
                    {{ children.data.chartData.secondSeriesData[0] }}
                  </div>
                  <div class="innerright">
                    {{ children.data.chartData.secondSeriesData[1] }}
                  </div>
                </div>
              </div>
            </div>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-单数据横向走势柱状图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 16"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <sixteenEcharts
              :title="children.data.chartData.title"
              :xaxisData="children.data.chartData.xaxisData"
              :xaxisTitle="children.data.chartData.xaxisTitle || []"
              :seriesData="children.data.chartData.seriesData"
            ></sixteenEcharts>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-折线柱状图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 17"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <seventeenEcharts
              v-if="children.data.chartType == 17"
              :dataEcharts="children.data.chartData"
            ></seventeenEcharts>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
          <!-- 图表-多项对比柱状图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 18"
            class="detail-item"
          >
            <h4
              style="font-size: 0.26rem; text-align: center; color: #005FE7;'"
              v-if="children.data.title"
            >
              {{ children.data.title }}
            </h4>
            <eighteenEcharts
              v-if="children.data.chartType == 18"
              :dataEcharts="children.data.chartData"
            ></eighteenEcharts>
            <p
              v-for="(i, index) of children.data.descriptions"
              :key="index"
              style="
                color: #2c3e50;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ i }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { getEvaluationReport } from "@/request/api";
import navigationBar from "@/components/navigationBar/navigationBar";
import reportItem from "@/components/reportItem/reportItem";
import score from "@/components/score/score";
import oneEcharts from "@/components/echarts/oneEcharts";
import twoEcharts from "@/components/echarts/twoEcharts";
import threeEcharts from "@/components/echarts/threeEcharts";
import fourEcharts from "@/components/echarts/fourEcharts";
import sevenEcharts from "@/components/echarts/sevenEcharts";
import eightEcharts from "@/components/echarts/eightEcharts";
import nineEcharts from "@/components/echarts/nineEcharts";
import tenEcharts from "@/components/echarts/tenEcharts";
import elevenEcharts from "@/components/echarts/elevenEcharts";
import thirteenEcharts from "@/components/echarts/thirteenEcharts";
import fourteenEcharts from "@/components/echarts/fourteenEcharts";
import seventeenEcharts from "@/components/echarts/seventeenEcharts";
import sixteenEcharts from "@/components/echarts/sixteenEcharts";
import eighteenEcharts from "@/components/echarts/eighteenEcharts";

export default {
  data() {
    return {
      showBack: true,
      name: "",
      back: 0,
      showScore: false,
      clickFalg: false,
      code: 0,
      userId: 0,
      tabInfos: [],
      info: {},
      screenHeight: document.documentElement.clientHeight,
      screenWidth: document.documentElement.clientWidth,
      pageList: [],
      pageHeight: 0,
      opacity: 0,
    };
  },
  components: {
    navigationBar,
    reportItem,
    score,
    oneEcharts,
    twoEcharts,
    fourEcharts,
    threeEcharts,
    sevenEcharts,
    eightEcharts,
    nineEcharts,
    tenEcharts,
    elevenEcharts,
    thirteenEcharts,
    fourteenEcharts,
    seventeenEcharts,
    sixteenEcharts,
    eighteenEcharts,
  },
  created() {
    this.name = JSON.parse(localStorage.getItem("name"));
    this.back = this.$route.query.back;
    this.code = this.$route.query.code;
    this.loginUsers = JSON.parse(localStorage.getItem("loginUser"));
    if (this.loginUsers) {
      this.userId = this.loginUsers.id;
    }
  },
  mounted() {
    this.getEvaluationReport(this.userId, this.code);
    this.getA4Size();
  },
  methods: {
    // 计算a4纸的大小
    getA4Size() {
      console.log(this.screenWidth);
      const proportion = 1060 / 750;
      this.pageHeight = parseInt(this.screenWidth * proportion);
    },
    handelClose(res) {
      this.showScore = res;
    },
    handelOpen() {
      this.showScore = true;
    },
    getEvaluationReport(userId, code) {
      let param = {
        id: userId,
        code: code,
      };
      getEvaluationReport(param).then((res) => {
        console.log(res);
        this.tabInfos = res.data.tabInfos;
        this.info = res.data;
        if (res.data.score == 0) {
          if (res.data.tabInfos.length <= 2) {
            // this.handelMore();
            // this.showMore = false;
            // this.processingData();
          } else {
            // this.showMore = true;
            // this.tabInfos = res.data.tabInfos.slice(0, 2);
            // this.processingData();
          }
        } else {
          // this.showMore = false;
          // this.tabInfos = res.data.tabInfos;
          // this.processingData();
        }
        this.$nextTick(() => {
          // 处理数据
          setTimeout(() => {
            let domList = Array.from(
              document.getElementsByClassName("detail-item")
            );
            console.log(domList);
            let allArr = [];
            let oneceArr = [];
            let allArrIndex = 0;
            let allHeight = 0;
            let maxHeihg = parseInt(
              this.pageHeight -
                parseInt((147 / 1060) * this.pageHeight) -
                parseInt((72 / 1060) * this.pageHeight)
            );
            console.log(maxHeihg);
            domList.forEach((item, index) => {
              allHeight += item.clientHeight;
              if (allHeight > maxHeihg) {
                allArr[allArrIndex] = oneceArr.concat([]);
                oneceArr = [item];
                allArrIndex += 1;
                allHeight = item.clientHeight;
              } else {
                oneceArr.push(item);
              }
              if (index == domList.length - 1 && oneceArr.length > 0) {
                allArr.push(oneceArr);
              }
            });
            console.log(allArr);
            // 计算每一页的高度
            // let pageTop =
            //   document.getElementsByClassName("detail-body-first")[0]
            //     .clientHeight + 20;
            let pageTop = this.pageHeight + 20;
            this.pageList = allArr;
            let yemeiHeigh = parseInt((95 / 1060) * this.pageHeight);
            this.$nextTick(() => {
              this.pageList.forEach((item, index) => {
                // 计算出起始高度高
                let startTop = pageTop * (index + 1) + yemeiHeigh;
                let nowPageHeight = 0;
                item.forEach((children, childrenIndex) => {
                  if (childrenIndex == 0) {
                    children.style.top = startTop + "px";
                  } else {
                    nowPageHeight += item[childrenIndex - 1].clientHeight;
                    children.style.top = nowPageHeight + startTop + "px";
                  }
                });
              });
            });
            this.opacity = 1;
          }, 1000);
        });
      });
    },
    centerFun(arr) {
      // 计算总共的长度
      let all = arr[0] + arr[1];
      let one = (arr[0] / all) * 100;
      return one + "%";
    },
    returnStyle(arr1, arr2) {
      // 计算每一个单位的宽度
      let oneWidht = 500 / (arr1[0] + arr1[1]);
      // 计算inner的宽度
      // let width = oneWidht * (arr2[0] + arr2[1]) + "px";
      let width = (oneWidht * (arr2[0] + arr2[1])) / 100 + "rem";
      // let left = ((arr1[0] - arr2[0]) * oneWidht) + 'px'
      let left = ((arr1[0] - arr2[0]) * oneWidht) / 100 + "rem";
      // 计算宽度
      return {
        "--center": this.centerFun(arr2),
        width,
        left,
      };
    },
    // 秒转分
    formatSeconds(value) {
      var secondTime = parseInt(value);// 秒
      var minuteTime = 0;// 分
      var hourTime = 0;// 小时
      if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
          //获取分钟，除以60取整数，得到整数分钟
          minuteTime = parseInt(secondTime / 60);
          //获取秒数，秒数取佘，得到整数秒数
          secondTime = parseInt(secondTime % 60);
          //如果分钟大于60，将分钟转换成小时
          if(minuteTime > 60) {
              //获取小时，获取分钟除以60，得到整数小时
              hourTime = parseInt(minuteTime / 60);
              //获取小时后取佘的分，获取分钟除以60取佘的分
              minuteTime = parseInt(minuteTime % 60);
          }
      }
      var result = "" + parseInt(secondTime) < 10 ? '0' + parseInt(secondTime) + "秒" : parseInt(secondTime) + "秒";
      if(minuteTime > 0) {
          result = "" + parseInt(minuteTime) < 10 ? '0' + parseInt(minuteTime) + "分钟" + result : parseInt(minuteTime) + "分钟" + result;
      }
      if(hourTime > 0) {
          result = "" + parseInt(hourTime) < 10 ? '0' + parseInt(hourTime) +"小时" + result : parseInt(hourTime) +"小时" + result;
      }
      return result;
    },
  },
};
</script>

<style scoped lang="less">
.evaluation_report {
  position: relative;
  background-color: #f4f4f4;
}

/deep/ .van-popup {
  background-color: transparent;
  border-radius: 0.1rem;
}

.one {
  // height: 100vh;
  background-image: url("../assets/images/cp_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 20px;

  .logo {
    width: 1.94rem;
    height: 0.5rem;
    position: absolute;
    left: 0.98rem;
    top: 29px;
  }

  .info {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 80px;
    transform: translateX(-50%);

    .title {
      font-size: 0.38rem;
      font-weight: 600;
      color: #1555e7;
      text-shadow: 0.02rem 0.02rem 0.02rem rgba(0, 61, 113, 0.5);
      -webkit-text-stroke: 0.01rem #ffffff;
      text-stroke: 0.01rem #ffffff;
      margin: 0 0 35px 0;
      font-family: "PingFangSC-Medium";
    }

    .item_info {
      display: flex;
      justify-content: center;
      margin-bottom: 18px;
      font-size: 0.3rem;
      font-weight: 400;
      color: #333333;

      .left_box {
        width: 34%;
        text-align: right;
      }
      .right_box {
        width: 80%;
        text-align: left;
      }
    }
  }

  .tips {
    width: 100%;
    font-size: 0.2rem;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.7;
    position: absolute;
    bottom: 22px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
}

.page {
  // height: 100vh;
  background-color: #ffffff;
  padding: 14px 0 18px 0;
  position: relative;
  margin-bottom: 20px;

  .title_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 0.01rem solid #1555e7;
    width: calc(100% - 0.3rem - 0.3rem);
    margin: 0 auto 0;

    .left_box {
      width: 1.71rem;
      height: 0.53rem;
      background-image: url("../assets/images/cp_left.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .logo1 {
        width: 0.81rem;
        height: 0.21rem;
        margin-top: 0.12rem;
      }
    }

    .right_box {
      width: 3.39rem;
      height: 0.32rem;
      background-image: url("../assets/images/cp_right.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0.1rem;

      .num {
        font-size: 0.18rem;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .footer_box {
    position: absolute;
    bottom: 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.01rem solid #1555e7;
    width: calc(100% - 0.3rem - 0.3rem);
    left: 50%;
    transform: translateX(-50%);

    .left_box {
      display: flex;
      align-items: center;
      font-size: 0.18rem;
      font-weight: 400;
      color: #1555e7;

      .footer1 {
        width: 0.35rem;
        height: 0.23rem;
        margin-right: 0.1rem;
      }

      .logo1 {
        width: 0.81rem;
        height: 0.21rem;
        margin-top: 0.12rem;
      }
    }

    .right_box {
      width: 0.77rem;
      height: 0.34rem;
      background-image: url("../assets/images/footer2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0.1rem;
      font-size: 0.22rem;
      font-weight: 400;
      color: #ffffff;
      font-size: 0.15rem;
      font-weight: 500;
      color: #ffffff;
    }
  }
}

.two {
  // height: 100vh;
  // background-color: #ffffff;
  // padding: 0.6rem 0.47rem 0.6rem 0.47rem;

  .title_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 0.01rem solid #1555e7;
    margin-bottom: 0.74rem;

    .left_box {
      width: 1.71rem;
      height: 0.53rem;
      background-image: url("../assets/images/cp_left.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .logo1 {
        width: 0.81rem;
        height: 0.21rem;
        margin-top: 0.12rem;
      }
    }

    .right_box {
      width: 3.39rem;
      height: 0.32rem;
      background-image: url("../assets/images/cp_right.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0.1rem;

      .num {
        font-size: 0.18rem;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .big_title_box {
    padding: 26px 0 20px 0;
    display: inline-block;
    width: auto;

    .big_title_box_wrap {
      border: 0.01rem solid #1555e7;
      border-radius: 0.08rem;
    }

    .big_title {
      font-size: 0.3rem;
      font-weight: 600;
      color: #005fe7;
      background: #e7f1ff;
      border-radius: 0.08rem;
      padding: 0.14rem 0.24rem;
      position: relative;
      left: -0.09rem;
      top: -0.11rem;
      line-height: 0.4rem;
    }
  }

  .content {
    padding: 0.16rem;
    background-color: #f5f9ff;
    font-size: 0.17rem;
    font-weight: 400;
    color: #333333;
    line-height: 0.4rem;
  }

  .footer_box {
    position: absolute;
    bottom: 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.01rem solid #1555e7;
    width: calc(100% - 0.3rem - 0.3rem);

    .left_box {
      display: flex;
      align-items: center;
      font-size: 0.18rem;
      font-weight: 400;
      color: #1555e7;

      .footer1 {
        width: 0.35rem;
        height: 0.23rem;
        margin-right: 0.1rem;
      }

      .logo1 {
        width: 0.81rem;
        height: 0.21rem;
        margin-top: 0.12rem;
      }
    }

    .right_box {
      width: 0.77rem;
      height: 0.34rem;
      background-image: url("../assets/images/footer2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0.1rem;
      font-size: 0.22rem;
      font-weight: 400;
      color: #ffffff;
      font-size: 0.15rem;
      font-weight: 500;
      color: #ffffff;
    }
  }
}

.item {
  // background-color: #ffffff;
  // border-radius: 0.12rem;
  // margin-bottom: 0.2rem;

  &:last-child {
    margin: 0;
  }

  .title {
    font-size: 0.28rem;
    font-family: "PingFangSC-Regular";
    font-weight: 600;
    color: #ffffff;
    padding: 0.2rem 0.3rem;
    background: #0067b0;
    border-radius: 0.45rem;
    margin-bottom: 0.3rem;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    &:after {
      content: "";
      width: 0.4rem;
      height: 0.58rem;
      background-image: url("../assets/images/title_left.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: -0.6rem;
      top: 50%;
      transform: translateY(-50%);
    }

    &:before {
      content: "";
      width: 0.4rem;
      height: 0.58rem;
      background-image: url("../assets/images/title_right.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: -0.6rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .text {
    font-size: 0.26rem;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: #333333;
    line-height: 0.46rem;

    .row {
      display: flex;
      border-top: 0.01rem solid #cccccc;
      border-left: 0.01rem solid #cccccc;
      border-right: 0.01rem solid #cccccc;
      border-bottom: 0.01rem solid #cccccc;

      &:last-child {
        border-bottom: 0.01rem solid #cccccc;
      }

      &:first-child {
        div {
          background-color: #e7f1ff;
          color: #1555e7;
        }
      }

      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 0.01rem solid #cccccc;
        text-align: center;
      }

      div:last-child {
        padding: 0.1rem;
        border-right: none;
      }
    }
  }
}

.more {
  font-size: 0.28rem;
  font-family: "PingFangSC-Regular";
  font-weight: 600;
  color: #ffffff;
  padding: 0.2rem 0.3rem;
  background: #0067b0;
  border-radius: 0.45rem;
  margin: 0.4rem 0 0 0;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.detail-item {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 0.3rem - 0.3rem);
  line-height: 0.5rem;
}

/deep/ .image_box img {
  width: 80% !important;
  display: block;
  margin: 0 auto 20px;
}

p {
  line-height: 0.4rem;
}

/deep/ .content_text p:first-child {
  padding-top: 0.16rem !important;
}

/deep/ .content_text p:last-child {
  padding-bottom: 0.16rem !important;
}

.result-table {
  width: 100%;
  border: 1px solid #ddd;
  margin: 0 auto;
  // text-align: center;
  padding: 20px 0;
  .result-table-body {
    width: 80%;
    margin: 0 auto;
    .result-table-body-header {
      overflow: hidden;
      .result-table-body-left-title {
        float: left;
        display: inline-block;
      }
      .result-table-body-right-title {
        float: right;
        display: inline-block;
      }
    }
    .result-table-body-center {
      width: 100%;
      height: 30px;
      text-align: left;
      border: 1px solid #ddd;
      .result-table-cent-left {
        display: inline-block;
        height: 100%;
        width: 50%;
        border-right: 1px solid #ddd;
        text-align: right;
        float: left;
        .result-table-left-centent {
          height: 100%;
          background: #f2961c;
          // width: 80%;
          float: right;
        }
      }
      .result-table-cent-right {
        display: inline-block;
        width: 49.8%;
        height: 100%;
        float: left;
        .result-table-right-centent {
          height: 100%;
          background: #ff0f;
          width: 80%;
          // float: right;
        }
      }
    }
    .result-table-body-bottom {
      width: 100%;
      height: 30px;
      text-align: left;
      // border: 1px solid #ddd;
      div {
        display: inline-block;
      }
      .result-table-cent-left {
        display: inline-block;
        height: 100%;
        width: 50%;
        // border-right:1px solid #ddd;
        text-align: right;
        float: left;
        .result-table-left-centent {
          height: 100%;
          // background: #f2961c;
          // width: 80%;
          float: right;
        }
      }
      .result-table-cent-right {
        display: inline-block;
        width: 49.8%;
        height: 100%;
        float: left;
        .result-table-right-centent {
          height: 100%;
          // background: #ff0f;
          // width: 80%;
          // float: right;
        }
      }
    }
  }
}

.results-body {
  width: 100%;
  height: 0.74rem;
  margin: 10px auto 30px;
  border-style: none;
  text-align: left;
  display: table;
  position: relative;
  // border: 1px solid #ddd;
  // padding: 20px;
  .results-list {
    display: table-cell;
    border-style: none;
    border-right: 1px solid #ddd;
    height: 0.74rem;
    color: #fff;
    text-align: center;
    position: relative;
    top: 50%;
    
    .results-label {
      display: inline-block;
      color: #000;
      font-size: 14px;
      position: absolute;
      bottom: -30px;
      right: 0;
    }

    .results-label-last {
      display: inline-block;
      color: #000;
      font-size: 14px;
      position: absolute;
      bottom: -30px;
      left: 0;
    }
  }
  .results-body-label {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 14px;
    color: red;
  }
}
.results-des {
  width: 100%;
  height: 0.74rem;
  position: relative;
  font-size: 14px;
  text-align: left;
  .results-lable {
    display: inline-block;
    text-align: center;
    line-height: 24px;
    position: absolute;
    width: 100px;
    margin-left: -50px;
    top: -70px;
    .results-line {
      width: 2px;
      display: inline-block;
      height: 1.4rem;
      // position: absolute;
      // left: 0;
      background: #f2961c;
    }
  }
}

#hengzhuCharts {
  text-align: center;
  h3 {
    font-size: 0.32rem;
    margin-bottom: 0.3rem;
  }
  h4 {
    font-size: 16px;
    color: #666;
  }
  .row-item {
    margin-bottom: 0.3rem;
    .row-wrap {
      div {
        width: 300px;
      }
      .text-title {
        font-size: 16px;
        line-height: 30px;
        vertical-align: middle;
        display: inline-block;
        width: 120px;
        text-align: center;
      }
      .text-zhu {
        display: inline-block;
        vertical-align: middle;
        height: 30px;
      }
      .text-zhl {
        background: blue;
        width: 40%;
      }
      .text-zhr {
        background: gray;
        width: 80%;
      }
      .text-zhul {
        background: green;
        width: 40%;
      }
      .text-zhur {
        width: 80%;
        background: yellow;
      }
    }
  }
}

.type15chart {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .centerZhu {
    width: 500px;
    height: 0.56rem;
    border-radius: 24px;
    position: relative;
    background-image: repeating-linear-gradient(
      90deg,
      #dfeaff,
      #dfeaff var(--center),
      #fff0e1 var(--center),
      #fff0e1 100%
    );
    .center_left_tips {
      position: absolute;
      left: 0;
      top: -30px;
      font-size: 0.24rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .center_right_tips {
      position: absolute;
      right: 0;
      top: -30px;
      font-size: 0.24rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .innerZhu {
      position: absolute;
      width: 250px;
      height: 0.56rem;
      border-radius: 24px;
      left: 0;
      background-image: repeating-linear-gradient(
        90deg,
        #0050f0,
        #0050f0 var(--center),
        #ff6f00 var(--center),
        #ff6f00 100%
      );
      .innerleft {
        font-size: 0.18rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #1856ac;
        position: absolute;
        left: 0;
        bottom: -23px;
      }
      .innerright {
        font-size: 0.18rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ff6f00;
        position: absolute;
        right: 0;
        bottom: -23px;
      }
    }
  }
  .centerZhu::before {
    content: attr(data-left);
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    position: absolute;
    left: 0;
    bottom: -23px;
  }
  .centerZhu::after {
    content: attr(data-right);
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    position: absolute;
    right: 0;
    bottom: -23px;
  }
}

.reading_guide {
  width: 6.02rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1rem;

  .reading_guide_title {
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 0.2rem;
  }

  .reading_guide_content {
    font-size: 0.2rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.4rem;
  }
}
</style>
